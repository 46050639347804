import { type ReadCallback } from 'i18next'

import commonEn from '#app/locales/en/common.json'
import zodEn from '#app/locales/en/zod.json'
import commonFr from '#app/locales/fr/common.json'
import zodFr from '#app/locales/fr/zod.json'

// TODO: find something better for the unknown type here
const resources: Record<string, Record<string, unknown>> = {
  en: {
    common: commonEn,
    zod: zodEn,
  },
  fr: {
    common: commonFr,
    zod: zodFr,
  },
}

export function resourceLoader(
  language: string,
  namespace: string,
  callback: ReadCallback,
) {
  const resource = resources[language]?.[namespace]
  if (resource) {
    callback(null, resource)
  } else {
    callback(
      new Error(
        `Missing translation for language: ${language}, namespace: ${namespace}`,
      ),
      null,
    )
  }
}
