import { startTransition } from 'react'

import { RemixBrowser } from '@remix-run/react'
import * as i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { hydrateRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { getInitialNamespaces } from 'remix-i18next/client'

import * as i18n from '@biogroup/i18n/config'

import { resourceLoader } from './utils/i18n.ts'

if (ENV.MODE === 'production') {
  import('./utils/monitoring.client.ts').then(({ init }) => init())
}

async function main() {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend(resourceLoader))
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    })

  startTransition(() => {
    hydrateRoot(document, <RemixBrowser />)
  })
}

main().catch(error => console.error(error))
